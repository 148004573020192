import React from "react";
import styles from "./Contents.module.css";

const ticketMessages = [
    {
        id: 'a',
        subject: 'The ticket # TICKET_NUMBER for EXAMPLE.COM; updated',
        body: [
            `We have received the additional information regarding ticket number TICKET_NUMBER. We have updated your 
            ticket for further troubleshooting. Our Tier 3 team will continue investigating the issue. We will contact you within 24-48 hours with an update or resolution to your ticket.`,
            `If you have additional questions or further information regarding your ticket, please do not hesitate to contact us and we will be glad to assist you. Thank you for your patience while we resolve this issue.`
        ],
    },
    {
        id: 'b',
        subject: 'The ticket # TICKET_NUMBER that was submitted for EXAMPLE.COM; pending reply',
        body: [
            `The ticket # TICKET_NUMBER that was submitted for EXAMPLE.COM, regarding CUSTOMERS_ISSUE, requires further information to process the support request. 
            In order for us to investigate this issue, please provide us with the following information to assist us in processing the support ticket:`,
            `REQUESTED_INFO`,
            `If we do not receive this information within 24 hours, we will send a reminder. The ticket will remain open for 48 hours for you to provide the requested 
            information. We look forward to assisting you in resolving this matter; if you have any questions or concerns about the requested information, please do not hesitate to contact us.`
        ],
    },
    {
        id: 'c',
        subject: 'The ticket # TICKET_NUMBER that was submitted for EXAMPLE.COM; pending reply (reminder)',
        body: [
            `The ticket # TICKET_NUMBER that was submitted for EXAMPLE.COM, regarding CUSTOMERS ISSUE, requires further information to process the support request. 
            In order for us to investigate this issue, please provide us with the following information to assist us in processing the support ticket:`,
            `REQUESTED_INFO`,
            `Within 24 hours, we will send a final request for this information. The ticket will remain open until we receive a reply. 
            We look forward to assisting you in resolving this matter; if you have any questions or concerns about the requested information, please do not hesitate to contact us.`
        ],
    },
    {
        id: 'd',
        subject: 'The ticket # TICKET_NUMBER that was submitted for EXAMPLE.COM; closed',
        body: [
            `The ticket # TICKET_NUMBER that was submitted for EXAMPLE.COM, regarding CUSTOMERS ISSUE, requires further information to process the support request. 
            In order for us to investigate this issue, please provide us with the following information to assist us in processing the support ticket:`,
            `REQUESTED_INFO`,
            `This is our final request to receive additional information for your ticket to troubleshoot it further. 
            At this time, we are closing your ticket. If you continue to have this issue, please contact us and we will open your issue and document the additional information required.`
        ],
    },
    {
        id: 'e',
        subject: 'The ticket # TICKET_NUMBER for EXAMPLE.COM; open',
        body: [
            `We have escalated your CUSTOMERS_ISSUE for further investigation. Our technical support team will contact you within 24-48 hours with an update or resolution to your ticket. The ticket number is TICKET_NUMBER; please include this ticket number in all further correspondence on this matter.`,


`If you have additional questions or further information regarding your ticket, please do not hesitate to contact us and we will be glad to assist you. Thank you for your patience while we resolve this issue.`
        ],
    },
];

const TicketMessage = ({ subject, body }) => (
    <div className={styles.ticketMessage}>
        <p><strong>Subject:</strong> {subject}</p>
        {body.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
        ))}
    </div>
);

const CommunTickets = () => {
    return (
        <div className={styles.CommunTickets}>
            <h2>CommunTickets</h2>
            {ticketMessages.map(({ id, subject, body }) => (
                <TicketMessage key={id} subject={subject} body={body} />
            ))}
        </div>
    );
};

export default CommunTickets;
