import React, { useState } from "react";
import styles from "./Contents.module.css";

const MailServers = () => {
    const HIDs = [
        'easily', '2560', 'Fairpoint', 'Bell', 'Rise', 'Dex', 'BlueGenesis',
        'Everyone', 'Aplus', 'EasyHosting', 'SaskTel', 'Telmex', '1112', '1134', '2741'
    ];
    
    const [mailServ, setMailServ] = useState({
        hostname: '',
        port: '',
        SPF: '',
        DKIM: '',
        Portal: '',
        Webmail: ''
    });

    const insertServer = (item) => {
        switch (item) {
            case 'easily':
                setMailServ({
                    hostname: '91.136.8.160',
                    port: 'mailc50.megamailservers.eu',
                    SPF: "\"0\"",
                    DKIM: "0.",
                    Portal: "0",
                    Webmail: "0"
                });
                break;
            case '2560':
                setMailServ({
                    hostname: '64.29.145.40',
                    port: 'securemail25.carrierzone.com',
                    SPF: "\"0\"",
                    DKIM: "hmail._domainkey.carrierzone.com.",
                    Portal: "0",
                    Webmail: "0"
                });
                break;
            case 'Fairpoint':
                setMailServ({
                    hostname: '64.29.151.40',
                    port: 'securemail26.carrierzone.com',
                    SPF: "\"0\"",
                    DKIM: "hmail._domainkey.carrierzone.com.",
                    Portal: "0",
                    Webmail: "0"
                });
                break;
            case 'Bell':
                setMailServ({
                    hostname: '69.156.240.33',
                    port: 'securemail2.megamailservers.com',
                    SPF: "\"v=spf1 a mx include:spfc9.megamailservers.com ~all\"",
                    DKIM: "hmail._domainkey.megamailservers.com.",
                    Portal: "0",
                    Webmail: "0"
                });
                break;
            case 'Rise':
                setMailServ({
                    hostname: '66.226.70.66',
                    port: 'securemail.risebroadband.net',
                    SPF: "\"v=spf1 include:spfc28.carrierzone.com ~all\"",
                    DKIM: "hmail._domainkey.carrierzone.com.",
                    Portal: "0",
                    Webmail: "0"
                });
                break;
            case 'Dex':
                setMailServ({
                    hostname: '0',
                    port: '0',
                    SPF: "\"v=spf1 include:spfc27.carrierzone.com ~all\"",
                    DKIM: "hmail._domainkey.carrierzone.com.",
                    Portal: "0",
                    Webmail: "0"
                });
                break;
            case 'BlueGenesis':
                setMailServ({
                    hostname: '0',
                    port: 'securemail2.megamailservers.com',
                    SPF: "\"v=spf1 a mx include:spfc1.megamailservers.com ~all\"",
                    DKIM: "hmail._domainkey.megamailservers.com.",
                    Portal: "https://hosting.bluegenesis.com/portal/",
                    Webmail: "0"
                });
                break;
            case 'Everyone':
                setMailServ({
                    hostname: '0',
                    port: 'smtp.everyone.net',
                    SPF: "\"v=spf1 a mx include:spfc40.carrierzone.com ~all\"",
                    DKIM: "hmail._domainkey.carrierzone.com.",
                    Portal: "https://portal.everyone.net/portal/",
                    Webmail: "0"
                });
                break;
            case 'Aplus':
                setMailServ({
                    hostname: 'mail.aplus.net',
                    port: 'securemail.aplus.net',
                    SPF: "\"v=spf1 include:spfc40.carrierzone.com ~all\"",
                    DKIM: "hmail._domainkey.carrierzone.com.",
                    Portal: "https://my.aplus.net/portal/",
                    Webmail: "https://webmail.aplus.net/"
                });
                break;
            case 'EasyHosting':
                setMailServ({
                    hostname: '216.251.32.97',
                    port: 'securemail.megamailservers.com',
                    SPF: "\"v=spf1 include:spfc0.megamailservers.com ~all\"",
                    DKIM: "hmail._domainkey.megamailservers.com.",
                    Portal: "0",
                    Webmail: "0"
                });
                break;
            case 'SaskTel':
                setMailServ({
                    hostname: 'mail.sasktel.net',
                    port: 'mail.sasktel.net',
                    SPF: "\"v=spf1 a mx include:spfc11.megamailservers.com ~all\"",
                    DKIM: "hmail._domainkey.megamailservers.com.",
                    Portal: "0",
                    Webmail: "https://webmail.sasktel.net/"
                });
                break;
            case 'Telmex':
                setMailServ({
                    hostname: '0',
                    port: 'mailc75.carrierzone.com',
                    SPF: "\"v=spf1 0 ~all\"",
                    DKIM: "hmail._domainkey.0.",
                    Portal: "0",
                    Webmail: "0"
                });
                break;
            case '1112':
                setMailServ({
                    hostname: '216.55.149.40',
                    port: 'webmail.digitalspaceportal.net',
                    SPF: "\"v=spf1 include:spfc45.carrierzone.com ~all\"",
                    DKIM: "hmail._domainkey.carrierzone.com.",
                    Portal: "https://hosting.digitalspaceportal.net/portal/",
                    Webmail: "https://webmail.digitalspace.net/"
                });
                break;
            case '1134':
                setMailServ({
                    hostname: '0',
                    port: 'secure28.carrierzone.com',
                    SPF: "\"v=spf1 include:spfc28.carrierzone.com ~all\"",
                    DKIM: "hmail._domainkey.carrierzone.com.",
                    Portal: "https://hosting.digitalspace.net/portal/",
                    Webmail: "https://hostingemail.digitalspace.net/"
                });
                break;
            case '2741':
                setMailServ({
                    hostname: '91.136.24.10',
                    port: 'mail.online.no',
                    SPF: "\"v=spf1 include:spf60.megamailservers.eu ~all\"",
                    DKIM: "hmail._domainkey.megamailservers.eu.",
                    Portal: "0",
                    Webmail: "https://epost.online.no/"
                });
                break;
            default:
                setMailServ({
                    hostname: '0',
                    port: '0',
                    SPF: "\"v=spf1 0 ~all\"",
                    DKIM: "0.",
                    Portal: "0",
                    Webmail: "0"
                });
                break;
        }
    };

    return (
        <div id={styles.MailServersCont}>
            <div id={styles.forChooseHID}>
                {HIDs.map((item) => (
                    <button className={styles.ButtonsServers} key={item} onClick={() => insertServer(item)}>
                        {item}
                    </button>
                ))}
            </div>            
                <div id={styles.ContainerColumns}>
                <div className={styles.columnsMain}>
                <p>Please ensure that you are using the following email client settings for the email accounts associated with your domain:</p><br />
                    <p><strong>Standard settings:</strong></p>
                    <ul>
                        <li>IMAP (incoming) server: {mailServ.hostname}</li>
                        <li>POP (incoming) server: {mailServ.hostname}</li>
                        <li>SMTP (outgoing) server: {mailServ.hostname}</li>
                        <li>IMAP: <em>143</em></li>
                        <li>POP: <em>110</em></li>
                        <li>SMTP: <em>1025</em> or <em>25</em></li>
                        <li>Username: your email account (for example)</li>
                        <li>Password: your email account password</li>
                        <li>SMTP Authentication: Enabled</li>
                        <li>SPA: disabled</li>
                    </ul>

                    <p>Please do not forget to enable "Keep a copy on the server option" (if you wish to keep emails on the server).</p><br />

                    <p><strong>Secure settings (preferable for Apple devices):</strong></p>
                    <ul>
                        <li>IMAP or POP (incoming) server: {mailServ.port}</li>
                        <li>SMTP (outgoing) server: {mailServ.port}</li>
                        <li>IMAP: <em>993</em></li>
                        <li>POP: <em>995</em></li>
                        <li>SMTP: <em>465</em></li>
                        <li>Username: your email account (for example)</li>
                        <li>Password: your email account password</li>
                    <li>SMTP Authentication: Enabled</li>
                    <li>SSL for both servers: ON</li>
                    <li>SPA: disabled</li>
                </ul>
                <p>Please do not forget to enable "Keep a copy on the server option" (if you wish to keep emails on the server).</p><br />

					<p>If the issue persists with the settings mentioned above, please provide us with a screenshot of the error you encounter while setting up the account. Additionally, include screenshots of your email client settings, along with your WAN IP. You can find your WAN IP by visiting <a href="https://www.whatismyip.com/">https://www.whatismyip.com/</a>.</p><br />

					<p>Furthermore, kindly provide the first four characters of your Portal (<span id="Portal"></span>) password to pass verification (alternatively, you may contact us from the administrative email address associated with your account) and authorize our Support Team to access your account for testing purposes. Any testing activities conducted will be thoroughly cleared upon completion, leaving no traces behind.</p><br />

					<p>Also, it is recommended that you check whether your email account password meets the following requirements:</p><br />

					<ul>
					  <li>Has at least twelve characters</li>
					  <li>Has at least one lowercase letter (a-z)</li>
					  <li>Has at least one uppercase letter (A-Z)</li>
					  <li>Has at least two numbers (0-9)</li>
                      <li>Has at least one special character (" ~!@#*^-$%&()+=_?/{}" )</li>
					  <li>Does not have three or more repeating characters</li>
					</ul><br />

					<p>Note that while our system may not enforce specific requirements from the above list, we suggest that you use them to improve account security and minimize vulnerability risks.</p><br />

					<p>The email account password can be updated using the Portal Mail Manager application or Webmail (choose 'Preferences' and then 'Password').</p> Webmail can be accessed at {mailServ.Webmail}<br />
            </div>
            <div className={styles.columnsMain}>
                <p>I see that your domain currently lacks DKIM and SPF records, as indicated in the logs below:</p><br />

                <p>To prevent any issues with sending emails from accounts associated with your domain to Gmail accounts, we recommend adding the following DNS records via your Portal DNS Manager application:</p><br />

                <p><strong>SPF record:</strong></p>
                <ul>
                    <li>Name: @</li>
                    <li>Type: TXT</li>
                    <li>Value: {mailServ.SPF}</li><br />
                </ul>

                <p><strong>DKIM record:</strong></p>
                <ul>
                    <li>Name: hmail._domainkey</li>
                    <li>Type: CNAME</li>
                    <li>Value: {mailServ.DKIM}</li>
                </ul><br />

                    <p>Ensure that the DKIM record's value is added with a dot (.) at the end.</p><br />

                    <p>After adding the records, allow 24-48 hours for propagation, and then an additional 24 hours for the DKIM record to be activated on our end. After this period, please retest to ensure proper functionality.</p><br />
            </div>
                </div>
        </div>
    );
};

export default MailServers;
