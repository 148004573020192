import React, { useEffect, useRef, useState } from "react";
import styles from "./DropDown.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faScrewdriverWrench, faGlobe, faNoteSticky, faSolarPanel } from '@fortawesome/free-solid-svg-icons'
import EmailPart from "./EmailPart.js";
import PortalPart from './PortalPart.js'
import CommunTemplatePart from './CommunTemplatePart.js'

const DropDown = ({setDropDown, dropDownRef}) => {
    

    const SubMenuItems = [
        'Tools',
        'Email',
        'Web',
        "Portal",
        'Communication Templates'
    ];
    
    const initialHoverState = SubMenuItems.reduce((acc, item) => {
        acc[item] = false;
        return acc;
    }, {});

    const [isHovered, setIsHovered] = useState(initialHoverState);

    const mouseToHover = (item) => {
        setIsHovered(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {}),
            [item]: true
        }));
    };

    const mouseNotToHover = () => {
        setIsHovered(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {})
        }));
    };

    const HoverElement = (item) => {
        switch (item) {
            case 'Tools':
                return isHovered[item] ? 'Tools' : '';
            case 'Email':
                return isHovered[item] ? <EmailPart /> : '';
            case 'Web':
                return isHovered[item] ? 'Web' : '';
            case 'Communication Templates':
                return isHovered[item] ? <CommunTemplatePart /> : '';
            case 'Portal':
                return isHovered[item] ? <PortalPart /> : '';
            default:
                return '';
        }
    };
    const Icons = [
        <FontAwesomeIcon icon={faScrewdriverWrench} />,
        <FontAwesomeIcon icon={faEnvelope} />,
        <FontAwesomeIcon icon={faGlobe} />,
        <FontAwesomeIcon icon={faSolarPanel} />,
        <FontAwesomeIcon icon={faNoteSticky} />

    ];

    return (
        <div id={styles.DropDownMainCont}>
            <div id={styles.DropDownCont} ref={dropDownRef}>
                <div id={styles.DropDownSubMenu}>
                    {
                        SubMenuItems.map((item, index) => {
                            let forIcon = Icons[index];
                            return (
                                <div
                                    className={styles.SubMenuItems}
                                    key={item}
                                    onMouseEnter={() => mouseToHover(item)}
                                >
                                    <span>{item}</span><span className={styles.forIcons}>{forIcon}</span>
                                </div>
                            );
                        })
                    }
            </div>
            <div id={styles.DropDownContent}>
                {Object.keys(isHovered).map(item => (
                    <div setDropDown={setDropDown} className={styles.forHoverEl} key={item}>
                        {HoverElement(item)}
                    </div>
                ))}
            </div>
        </div>
        </div>
    );
};

export default DropDown;
