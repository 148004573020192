import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Main from "./Main/Main";
import Whois from "./Main/Whois";
import Telnet from "./Main/Telnet";
import MailServers from "./Main/MailServers";
import CommunTickets from "./Main/CommunTickets";
import AplusIssue from "./Main/AplusIssue";
import AbuseFiles from "./Main/AbuseFiles";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="mailservers" element={<MailServers />} />
          <Route path="communtickets" element={<CommunTickets />} />
          <Route path="aplusissue" element={<AplusIssue />} />
          <Route path="abusefiles" element={<AbuseFiles />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
