import React from "react";
import styles from "./Contents.module.css";

const AbuseFiles = () => {
    return (
        <>
            <div className={styles.abuseFiles}>
                <p><strong>Subject: Client Abuse Notification: Files Disabled || EXAMPLE.COM</strong></p><br />
                <p>This email is in regard to the domain: EXAMPLE.COM. In order to ensure the optimal performance and security of our servers, we are proactively contacting you to notify you of the following suspensions with your hosted services.</p>
                <p>The following files on your web hosting space have been disabled because they were identified as malicious content:</p><br />
                <p><strong>INSERT_DELETED_FILE_NAMES</strong></p><br />
                <p>All the specified files have been transferred to the quarantine directory(ies) that is(are) located in the root directory of your web space.</p>
                <p>Please immediately run updated antivirus scans on all your devices in use and change all the passwords associated with managing your web hosting content.</p>
                <p>Be advised that the strong (required) password should contain upper- and lower-case letters, numbers and special characters. In addition, please remove any clear text files containing unencrypted usernames and passwords. Kindly review all the code and content to ensure no further vulnerabilities remain.</p>
                <p>Once the above actions have been done, please move all the required files from the quarantine directory back to their initial location.</p>
                <p>Please ensure that any files you upload to your website are secure as well.</p>
                <p>The above changes are in accordance with our commitment to providing our clients with a reliable and secure web hosting platform. We apologize for any inconvenience that this may cause.</p>
                <p>If you have any additional inquiries regarding the issue, or if any further information becomes available which you would like to provide to us, please do not hesitate to contact us and we will be glad to assist you further.</p>
            </div>
            <div className={styles.abuseFiles}>
                <p><strong>Subject: Client Abuse Notification: Fraudulent Activities || EXAMPLE.COM</strong></p><br />
                <p>This email is in regard to the domain DOMAIN. In accordance with our abuse policy, this is a notice of action taken against the above specified domain. Ticket # TICKET_NUMBER has been created for this issue.</p>
                <p>Please be informed that outbound mail for EMAIL_ACCOUNT has been blocked as the account has been sending spam.</p>
                <p>You should immediately change all your passwords and run updated antivirus scans on all your computers. Once you have confirmed all issues and vulnerabilities to have been resolved, you may contact us requesting outbound mail privileges be restored for this account. Note that during the unblock process, the affected email account will be accessed via SMTP protocol for testing purposes; this is a normal process used to validate proper restoration of account capabilities.</p>
                <p>If you believe we have closed this issue prematurely or in error, please do not hesitate to contact us with more information on your situation.</p><br />
                <p>We sincerely appreciate your immediate attention to this important matter. If you have any additional inquiries regarding the issue, or if any further information becomes available which you would like to provide to us, please do not hesitate to contact us and we will be glad to assist you further.</p>
            </div>
            <div className={styles.abuseFiles}>
                <p><strong>Subject: Client Abuse Notification: Suspicious Activities || example.com</strong></p><br />
                <p>This email is in regard to the domain [domain]. In accordance with our abuse policy, this is a notice of action taken against the above-specified domain. Ticket # 1234567 has been created for this issue.</p>
                <p>Please be informed that the password for [domain] has been scrambled to prevent continued suspicious activities.</p>
                <p>You should immediately change all your passwords and run updated antivirus scans on all your computers. Once the password on the affected email account is updated, you may resume normal operations on the account.</p>
                <p>We sincerely appreciate your immediate attention to this important matter. If you have any additional inquiries regarding the issue, or if any further information is required, please do not hesitate to reach out to us. We are here to assist you and ensure that any concerns are addressed promptly and effectively.</p>
            </div>
        </>
    );
};

export default AbuseFiles;
