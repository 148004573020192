import React, { useState } from "react";
import styles from "./Contents.module.css";

const AplusIssue = () => {
    const [forAplusPass, setForAplusPass] = useState({
        AplusPass: '',
        AplusUser: ''
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setForAplusPass((prevState) => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = () => {
        console.log("Submitted values:", forAplusPass);
        // Add any additional logic for submission here
    };

    return (
        <div className={styles.heHe}>
            <p>Due to security reasons, the password should be updated.</p><br />
            <p>I see the account associated with your domain has a billing-related issue. It can cause the loop you are encountering. To resolve it, the Billing Department should be contacted at billing@cs.aplus.net or 855-791-8966 2 - 1.</p><br />
            <p>Have you not received the password reset link?</p><br />
            <p>Have you checked the Spam and Junk folders too?</p><br />
            <p>Please specify the email address you are expecting to receive the password reset link at.</p><br />
            <p>I have sent a verification code to the email address associated with your account. Please provide me with it.</p><br />
            <p>Do you remember the first four characters of your Portal password (the last one you remember)?</p><br />
            <input
                type="text"
                id="AplusPass"
                placeholder="Temp password..."
                value={forAplusPass.AplusPass}
                onChange={handleInputChange}
            />
            <input
                type="text"
                id="AplusUser"
                placeholder="Username..."
                value={forAplusPass.AplusUser}
                onChange={handleInputChange}
            />
            <div>
                <p>I have set up a new password: "<span>{forAplusPass.AplusPass}</span>" (no quotes)</p>
                <p>Your Portal username is <span>{forAplusPass.AplusUser}</span></p>
                <p>Ensure that you are logging in at <a href="https://my.aplus.net/portal/">https://my.aplus.net/portal/</a></p>
                <p>Once you log in to your Portal, update the password as soon as possible:</p>
                <p>1. Log in to your Portal.</p>
                <p>2. From the account drop-down in the top-right corner of your dashboard, select "General."</p>
                <p>3. Choose "Password."</p>
                <p>A strong password does not contain your user name, real name, or any other sensitive information, is significantly different from your previous passwords and meets the following requirements:</p>
                <p>- Has at least twelve characters</p>
                <p>- Has at least one lowercase letter (a-z)</p>
                <p>- Has at least one uppercase letter (A-Z)</p>
                <p>- Has at least two numbers (0-9)</p>
                <p>- Has at least one special character ( ~!@#*^-$%&()+=_?/{})</p>
                <p>- Does not have three or more repeating characters</p>
                <p>Note that while our system may not enforce specific requirements from the above list, we suggest that you use them to improve account security and minimize vulnerability</p>
            </div>
        </div>
    );
};

export default AplusIssue;
