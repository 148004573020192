import React from "react";
import styles from "./Parts.module.css";
import { Link } from "react-router-dom";

const PortalPart = ({setDropDown}) => {
    return(
        <div id={styles.EmailPartCont}>
            <div className={styles.forColumns}>
                <Link onClick={()=>setDropDown(false)} className={styles.ItemsMain} to="aplusissue">Aplus issue</Link>
            </div>
        </div>
    );
};

export default PortalPart;