import React, { useEffect, useRef, useState } from "react";
import styles from "./Main.module.css";
import { Link, Outlet } from "react-router-dom";
import Logo from './logo.jpg';
import DropDown from "./DropDown";

const Main = () => {
    const [DropDownSt, setDropDown] = useState(false);
    const dropDownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
            setDropDown(false);
        }
    };

    useEffect(() => {
        if (DropDownSt) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        // Cleanup function to remove event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [DropDownSt]);

    return (
        <div id={styles.wrapper}>
            <div>header</div>
            <header>
                <Link to="/" id={styles.HeaderLogo}><img src={Logo} alt="Logo" /></Link>
                <button 
                    onClick={() => setDropDown((prevState) => !prevState)} 
                    ref={buttonRef} 
                    id={styles.MainButton}
                >
                    Категорії
                </button>
                {DropDownSt && <DropDown setDropDown={setDropDown} dropDownRef={dropDownRef} />}
            </header>
            <main>
                <div id={styles.mainContent}><Outlet /></div>
            </main>
            <footer>footer</footer>
        </div>
    );
};

export default Main;
