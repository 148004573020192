import React from "react";
import styles from "./Parts.module.css";
import { Link } from "react-router-dom";

const CommunTemplatePart = ({setDropDown}) => {
    return(
        <div id={styles.EmailPartCont}>
            <div className={styles.forColumns}>
                <Link onClick={()=>setDropDown(false)} className={styles.ItemsMain} to="communtickets">Communication (tickets)</Link>
                <Link onClick={()=>setDropDown(false)} className={styles.ItemsMain} to="abusefiles">Abuse files</Link>
            </div>
        </div>
    );
};

export default CommunTemplatePart;